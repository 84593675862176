import axios from 'axios';
import qs from 'qs';
import { formatDateSelectors } from '../helpers/date';


// Mutation types
const SET_TOKEN_SESSIONS = 'tokenSessions/SET_TOKEN_SESSIONS';


const ENDPOINTS = {
  GET_TOKEN_SESSIONS: '/tokenSessions',
};

function initialState() {
  return {
    tokenSessions: [],
  };
}
// initial state
const state = initialState();

// actions
const actions = {
  async GET_TOKEN_SESSIONS({ commit }, data) {
    const params = formatDateSelectors(data);
    try {
      const response = await axios.get(ENDPOINTS.GET_TOKEN_SESSIONS, {
        params,
        paramsSerializer: params => qs.stringify(params, { allowDots: true })
      });
      commit(SET_TOKEN_SESSIONS, {
        count: response.data.count,
        data: response.data.data
      });
      return response.data;

    } catch {
      throw new Error('Something went wrong fetching transactions. Please try again later.');
    }
  }

};

// getters
const getters = {
  getTokenSessions: state => state.tokenSessions,
};

// mutations
const mutations = {
  [SET_TOKEN_SESSIONS](state, data) {
    state.tokenSessions = data;
  },
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};